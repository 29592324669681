<template>
  <v-container>
    <v-row v-if="error">
      <v-col cols="12" sm="6" offset-sm="3">
        <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card>
          <v-card-text>
            <v-container>
              <v-btn color="red" dark @click="onSignin">
                <v-icon left>mdi-google</v-icon>Sign In with Google
              </v-btn>
              <hr />
              <br />
              <br />
              <v-form ref="form">
                <v-col cols="12">
                  <v-text-field v-model="email" label="Email" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :type="'password'"
                    v-model="password"
                    label="Password"
                    required
                    @keyup.enter="signinUserPass"
                  ></v-text-field>
                </v-col>
                <v-btn color="primary" @click="signinUserPass"
                  >Sign In with Userame and Password</v-btn
                >
              </v-form>
              <hr />
              <v-col cols="12">
                Forgot your password?
                <router-link style="color: #1b5e20" tag="a" to="/reset-password"
                  >Reset your password here</router-link
                >
              </v-col>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import firebase from "firebase/app";
import * as userService from "../services/userService";
import * as utilsService from "../services/utilsService";
import * as organisationService from "../services/organisationService";
import * as logService from "../services/logService";

export default {
  data: () => ({
    email: "",
    password: "",
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  mounted() {
    const me = this;
    this.$store.dispatch("setLoading", true);
    firebase
      .auth()
      .getRedirectResult()
      .then(function (result) {
        if (result.credential) {
          //   // This gives you a Google Access Token. You can use it to access the Google API.
          //   var token = result.credential.accessToken;
          //   // [START_EXCLUDE]
          //   //document.getElementById('quickstart-oauthtoken').textContent = token;
          // } else {
          // //  document.getElementById('quickstart-oauthtoken').textContent = 'null';
          //   // [END_EXCLUDE]
          // }
          // The signed-in user info.
          // var user = result.user;
          me.checkUser(me, result);
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //  var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //  var credential = error.credential;
        // [START_EXCLUDE]
        if (errorCode === "auth/account-exists-with-different-credential") {
          alert(
            "You have already signed up with a different auth provider for that email."
          );
          // If you are using multiple auth providers on your app you should handle linking
          // the user's accounts here.
        } else {
          console.error(error);
        }
        // [END_EXCLUDE]
      })
      .finally(() => {
        this.$store.dispatch("setLoading", false);
      });
  },
  methods: {
    signinUserPass() {
      var me = this;
      var activeUser = false;
      Vue.prototype.$db
        .collection("users")
        .where("email", "==", me.email.toLowerCase())
        .get()
        .then((userSnapshot) => {
          if (userSnapshot.size == 0) {
            me.signinError(me);
            return null;
          }
          if (userSnapshot.size > 0) {
            console.log("User sign-in success");
            let userData = userSnapshot.docs.map((documentSnapshot) => {
              let user = documentSnapshot.data();
              user.id = documentSnapshot.id;
              me.murmurCheck(me, user);
              if (!user.isEnabled) {
                me.signinError(me);
                return null;
              }
              activeUser = true;
              return user;
            });
            if (activeUser) {
              firebase
                .auth()
                .signInWithEmailAndPassword(me.email, me.password)
                .then((result) => {
                  console.log(result);
                  me.setUser(me, result, userData[0]);
                })
                .catch((error) => {
                  console.log(error);
                  me.signinError(me);
                });
            } else {
              me.signinError(me);
            }
          }
        });
    },

    murmurCheck(me, user) {
      let murmur = utilsService.getCookie("murmur");
      if (user.murmur != undefined && user.murmur.length > 0) {
        if (user.murmur !== murmur) {
          if (
            confirm(
              "You are currently logged in on another device at another location\n By continuing you will force logout on the other device\nDo you wish to continue?"
            )
          ) {
            user.murmur = murmur;
            logService
              .save({
                type: "murmur-change",
                user: Object.assign({}, user),
              })
              .then((x) => {
                console.log(x);
                console.log("logged force user logout");
              });
          } else {
            me.signinError(me);
            me.$router.push("/signin");
          }
        }
      } else {
        user.murmur = murmur;
      }
      me.$store.dispatch("saveUser", user);
    },
    onSignin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      var me = this;
      firebase
        .auth()
        .signInWithRedirect(provider)
        .then((result) => {
          this.checkUser(me, result);
        })
        .catch((error) => {
          console.log(error);

          me.signinError(me);
        });
    },
    checkUser(me, result) {
      Vue.prototype.$db
        .collection("users")
        .where("email", "==", result.user.email.toLowerCase())
        .get()
        .then((userSnapshot) => {
          if (userSnapshot.size > 0) {
            console.log("User sign-in success");
            let userData = userSnapshot.docs.map((documentSnapshot) => {
              let user = documentSnapshot.data();
              me.murmurCheck(me, user);
              user.id = documentSnapshot.id;
              if (
                result.additionalUserInfo != undefined &&
                result.additionalUserInfo != null &&
                result.additionalUserInfo.profile != undefined &&
                result.additionalUserInfo.profile != null
              ) {
                if (
                  user.firstName == undefined ||
                  user.firstName == null ||
                  user.firstName.length === 0
                ) {
                  user.firstName = result.additionalUserInfo.profile.given_name;
                }
                if (
                  user.lastName == undefined ||
                  user.lastName == null ||
                  user.lastName.length === 0
                ) {
                  user.lastName = result.additionalUserInfo.profile.family_name;
                }
                if (
                  user.photoURL == undefined ||
                  user.photoURL == null ||
                  user.photoURL.length === 0
                ) {
                  user.photoURL = result.additionalUserInfo.profile.picture;
                }
              }
              if (user.status !== "Active") {
                user.status = "Active";
              }
              me.$store.dispatch("saveUser", user);
              return user;
            });

            me.setUser(me, result, userData[0]);
          } else {
            me.signinError(me);
          }
        });
    },
    signinError(me) {
      me.$store.commit("setUser", null);
      me.$store.dispatch("setToast", {
        message: "Sign-in error",
        color: "red darken-4",
      });
    },
    setUser(me, result, userData) {
      console.log(result);

      Vue.prototype.$db
        .collection("organisations")
        .doc(userData.organisationId)
        .get()
        .then((organisationDoc) => {
          const org = organisationDoc.data();
          org.id = organisationDoc.id;
          me.$store.commit("setCurrentOrganisation", org);
          me.$store.commit("setUser", userData);

          userService.registerUserListener(me.$store, userData.id);
          organisationService.registerOrganisationListener(this.$store, org.id);
          me.$router.push("/");
        })
        .catch(function (error) {
          console.log(error);
          me.$store.commit("setUser", null);
          me.$store.dispatch("setToast", {
            message: "Sign-in error",
            color: "red darken-4",
          });
        });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>
