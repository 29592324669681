import Vue from "vue";

function getRef() {
  return Vue.prototype.$db.collection("logs");
}

export async function getById(id) {
  return getRef()
    .doc(id)
    .get();
}

export async function list() {
  return getRef()
    .where("isDeleted", "==", false)
    .get();
}
export async function listRemoved() {
  return getRef()
    .where("isDeleted", "==", true)
    .get();
}
export async function remove(id, user) {
  return getRef()
    .doc(id)
    .update({ isDeleted: true, deletedBy: user, deletedOn: new Date() });
}

export async function unRemove(id) {
  return getRef()
    .doc(id)
    .update({ isDeleted: false, deletedBy: null, deletedOn: null });
}
export async function save(payload) {
  const obj = Object.assign({}, payload);
  obj.createdOn = new Date();
  if (obj.id != undefined) {
    return getRef()
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef().add(obj);
  }
}
